import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import AboutHero from '../components/AboutHero'
import MeetCouncil from '../components/MeetCouncil'
import MembersComponent from '../components/MembersComponent'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

/**
 * This is about us page which contains 3 components
 * 
 * @param {object} data
 * @param {object} pageContext
 */
export default function councilPeopleTemplate({ data }) {
  const { allWpMenu,
    wpPage: { translations, language, title,
      Council_and_people_template: { header, councilRepeater, memberRepeater } },
    allWp: { nodes: [optionsPage] } } = data

  const shareContent = header.description.replace(/<[^>]+>/g, '').slice(0, 160);

  return (
    <>
      <Seo title={title} description={shareContent} image={header.image?.file?.aboutHeroImage?.fluid?.src} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <div className="about-us">
        <AboutHero pageTitle={title} heroData={header} />
        <MeetCouncil councilData={councilRepeater} language={language} />
        <MembersComponent memberData={memberRepeater} language={language} />
      </div>
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}


councilPeopleTemplate.propTypes = {
  data: PropTypes.object
}

export const aboutPageQuery = graphql`
  query aboutPageByID($id: String) {
    wpPage(id: {eq: $id}) { 
      translations {
        uri
      }
      language {
        locale
      }
      title
      Council_and_people_template {
        header {
          description
          title
          image {
            file: localFile {
              aboutHeroImage: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        councilRepeater {
          name
          description
          email
          image {
            file: localFile {
              councilCardImage: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          name
          position
          description
          email
          socialsRepeater {
            fieldGroupName
            url
            socialIconType
          }
        }
        memberRepeater {
          description
          email
          name
          image {
            file: localFile {
              memberCardImage: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
      }
    }
  }
`
